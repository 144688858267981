import React from 'react';
import './App.css';

import Day from './Day';
// import Button from './Button';
// import ListTaskContainer from './ListTaskContainer';
// import CreateTaskForm from './CreateTaskForm';

class NoteScreen extends React.Component {
    render() {
        return (
            <>
            <div className="buttons">
                <div className="row">
                    <div className="col">
                        
                    </div>
                </div>
            </div>
        <Day updater={this.props.updateDay} today={this.props.today} notes={this.props.notes} noteChange={this.props.noteChange} noteSave={this.props.noteSave}/>
        </>
        /* <CreateTaskForm addTask={this.props.addTask}/> */
        /* <ListTaskContainer list={this.props.taskList} removeTask={this.props.removeTask}/> */
        );
    }
}

export default NoteScreen;
