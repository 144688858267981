import React from 'react';

class Note extends React.Component {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
    }
    componentDidMount(){
        this.textInput.current.focus();
    }

    onFocus(e) {
        e.currentTarget.setSelectionRange(
            e.currentTarget.value.length,
            e.currentTarget.value.length
        )   
    }
      
    render() {
        return <textarea 
            className="form-control" 
            type="text" 
            value={this.props.notes} 
            onChange={(e) => this.props.noteChange(e)} 
            onBlur={(e) => this.props.noteSave(e)} 
            onFocus={this.onFocus}
            ref={this.textInput}
        />
    }
    
}

export default Note;
