import React from 'react';

class Privacy extends React.Component {
    render() {
        return (
            <div>
                <h1>Privacy</h1>
            </div>
        );
    }
}

export default Privacy;