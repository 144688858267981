import React from 'react';
import Note from './Note';
import Button from './Button';
import simpleDate from './SimpleDate';

class Day extends React.Component {
    render() {
        var options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };
        const mobileOptions = { weekday: 'short', year: '2-digit', month: 'short', day: 'numeric' };
        return (
            <>
                <div className="h6 d-flex">
                    <Button direction={-1} updater={this.props.updater}/>
                    <Button direction={1} updater={this.props.updater}/>
                    <span className="d-none d-sm-inline date-text">{simpleDate.simpleDateToDate(this.props.today).toLocaleDateString("en-US", options).replace(/,/, "")}</span>
                    <span className="d-inline d-sm-none date-text">{simpleDate.simpleDateToDate(this.props.today).toLocaleDateString("en-US", mobileOptions).replace(/,/, "")}</span>
                    {/* {this.props.today === simpleDate.simpleDate(new Date()) && <div className="h6 today-marker"><span className="badge badge-success">Today</span></div>} */}
                </div>
                <div className="row notes">
                    <div className="col mt-3 mb-3">
                        {/* <div className="h5">Notes</div> */}
                        <Note notes={this.props.notes} noteChange={this.props.noteChange} noteSave={this.props.noteSave}/>
                    </div>
                </div>
            </>
            /* <div className="row">
                    <div className="col-6">
                        <div className="h5">To do</div>
                    </div>
                    <div className="col-6">
                        <div className="h5"></div>
                    </div>
                </div> */
        )
    }
}

export default Day;